import React from 'react';
import PropTypes from 'prop-types';

export default function Btn({ children, onClick, disabled }) {
    return (
        <div className='btn-container'>
            <button disabled={disabled} onClick={onClick} type='submit' className='btn'>
                {children}
            </button>
        </div>
    );
}

Btn.propTypes = {
    children: PropTypes.node.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
};

Btn.defaultProps = {
    disabled: false,
    onClick: undefined,
};

/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Components
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import useModal from "../../hooks/useModal";
import Modal from "../Modal/Modal";

// Utils
import { dateToHours, dateToString } from "../../utils/utils";

// Context
import NotificationContext from "../../contexts/notification-context";

// VARIABLES
const { REACT_APP_WEBSERVICE_URL } = process.env;

const getDateRange = () => {
    const today = new Date();
    today.setMonth(today.getMonth() - 1);
    const dateStart = today.toISOString().split("T")[0];
    const dateEnd = new Date(today.setMonth(today.getMonth() + 3)).toISOString().split("T")[0];

    return { dateStart, dateEnd };
};
const { dateStart, dateEnd } = getDateRange();

const RenderDayHeaderContent = (args) => {
    const { view, isToday, date } = args;

    const displayDate = (d) => formatDate(d, { day: "numeric" });

    const displayDay = (d) => formatDate(d, { weekday: "narrow", locale: "fr" });
    const displayDayLong = (d) => formatDate(d, { weekday: "long", locale: "fr" });

    if (view.type === "timeGridWeek") {
        return (
            <p className={`calendar__dayHeader ${isToday && "today"}`}>
                <span className="day">{displayDay(date)}</span>
                <span className="date">{displayDate(date)}</span>
            </p>
        );
    }
    if (view.type === "timeGridFourDays") {
        return (
            <p className={`calendar__dayHeader ${isToday && "today"}`}>
                <span className="day text-capitalize">{displayDayLong(date)}</span>
                <span className="date">{displayDate(date)}</span>
            </p>
        );
    }
    if (view.type === "timeGridDay") {
        return <span>{formatDate(date, { weekday: "long", month: "long", year: "numeric", day: "numeric", locale: "fr" })}</span>;
    }

    return null;
};

const RenderLabelContent = (args) => {
    const { time } = args;

    const msToLabel = (ms) => {
        if (ms !== 0) {
            const milliseconds = ms - 3600000;
            return formatDate(milliseconds, { hour: "2-digit", minute: "2-digit", meridiem: false, hour12: false });
        }
        return null;
    };

    return (
        <>
            <span className="calendar__label">{msToLabel(time.milliseconds)}</span>
        </>
    );
};

const RenderEventContent = (args) => {
    const { event } = args;
    const { bateau, couleur_bateau, couleur_texte_bateau } = event._def.extendedProps;

    return (
        <>
            <div style={{ backgroundColor: `${couleur_bateau}` }} className="calendar__event">
                <p style={{ color: `${couleur_texte_bateau}` }}>{bateau}</p>
            </div>
        </>
    );
};

const scrollToNowIndicator = (args) => {
    const nowIndicator = args.el;
    nowIndicator.scrollIntoView({ behavior: "smooth", block: "center" });
};

export default function Calendar({ openNotification }) {
    const history = useHistory();
    const [notificationsNotSeen] = useContext(NotificationContext);
    const { modalIsShowing, toggleModal } = useModal();
    const refCalendar = useRef(null);
    const [calendarApi, setCalendarApi] = useState(null);

    // Get sessions
    const [sessions, setSessions] = useState([]);
    const getSessions = () => {
        const token = localStorage.getItem("dockersBastiaToken");
        const options = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Request-Headers": " Authorization",
                Authorization: `${token}`,
            },
            method: "POST",
            body: JSON.stringify({
                methode: "agenda",
                param1: dateStart,
                param2: dateEnd,
            }),
        };
        fetch(REACT_APP_WEBSERVICE_URL, options)
            .then((res) => {
                if (res.status === 401) {
                    history.push("/login");
                }
                return res.json();
            })
            .then((data) => {
                if (data && data.session) {
                    console.log(data.session);
                    setSessions(data.session);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };
    useEffect(() => {
        getSessions();
    }, []);

    // Modal session details
    const [bateau, setBateau] = useState("bateau");
    const [poste, setPoste] = useState("poste");
    const [horaires, setHoraires] = useState();
    const [prime, setPrime] = useState();

    useEffect(() => {
        if (refCalendar) {
            setCalendarApi(refCalendar.current.getApi());
        }
    }, [refCalendar]);

    // Ajoute un badge si il y a des notifications non lues
    useEffect(() => {
        const notificationIcon = document.getElementsByClassName("fc-icon-notification");
        if (notificationsNotSeen > 0) {
            notificationIcon[0].classList.add("badge");
        } else {
            notificationIcon[0].classList.remove("badge");
        }
    }, [notificationsNotSeen]);

    const detailsEvent = (eventInfos) => {
        setBateau(eventInfos.event._def.extendedProps.bateau);
        setPoste(eventInfos.event._def.extendedProps.poste);
        setPrime(eventInfos.event._def.extendedProps.prime);
        setHoraires({ start: eventInfos.event.startStr, end: eventInfos.event.endStr });
        toggleModal();
    };

    return (
        <div className="calendar">
            <FullCalendar
                ref={refCalendar}
                footerToolbar={{
                    left: "timeGridDay,timeGridFourDays,timeGridWeek",
                    right: "prev,next",
                }}
                headerToolbar={{
                    right: "refresh,today,notification",
                }}
                views={{
                    timeGridFourDays: {
                        type: "timeGrid",
                        duration: { days: 4 },
                        buttonText: "4 jours",
                    },
                }}
                initialView="timeGridWeek"
                customButtons={{
                    refresh: {
                        icon: "refresh",
                        text: "refresh",
                        click: getSessions,
                    },
                    today: {
                        icon: "today",
                        text: "today",
                        click: () => calendarApi.today(),
                    },
                    notification: {
                        icon: "notification",
                        text: "notification",
                        click: () => openNotification(),
                    },
                }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                events={sessions}
                nowIndicator
                locale="fr"
                timeZone="UTC"
                firstDay={1}
                scrollTime="5:00:00"
                allDaySlot={false}
                stickyHeaderDates
                height="var(--vh100)"
                dayHeaderContent={RenderDayHeaderContent}
                slotLabelContent={RenderLabelContent}
                eventContent={RenderEventContent}
                eventClick={(eventInfos) => detailsEvent(eventInfos)}
                nowIndicatorDidMount={(args) => scrollToNowIndicator(args)}
                slotLabelFormat={{
                    hour: "2-digit",
                    minute: "2-digit",
                }}
                buttonText={{
                    today: "Aujourd'hui",
                    month: "Mois",
                    week: "Semaine",
                    day: "Jour",
                }}
            />

            {modalIsShowing && (
                <Modal isShowing={modalIsShowing} hide={toggleModal}>
                    {horaires && (
                        <div className="mb-2">
                            <p className="text-bold">Date & horaires</p>

                            <p>
                                {dateToString(horaires.start)}, de {dateToHours(horaires.start)} à {dateToHours(horaires.end)}
                            </p>
                        </div>
                    )}
                    <div>
                        <p className="text-bold">Bateau</p>
                        <p>{bateau}</p>
                    </div>
                    {poste && (
                        <div className="mt-2">
                            <p className="text-bold">Poste</p>
                            <p>{poste}</p>
                        </div>
                    )}
                    {prime && prime > 0 && (
                        <div className="mt-2">
                            <p className="text-bold">Prime</p>
                            <p>{prime} €</p>
                        </div>
                    )}
                </Modal>
            )}
        </div>
    );
}

Calendar.propTypes = {
    openNotification: PropTypes.func.isRequired,
};

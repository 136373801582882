import React from 'react';
import PropTypes from 'prop-types';

export default function Loader({ white }) {
    return (
        <div className='spinner-box'>
            <div className={`three-quarter-spinner ${white && 'white'}`} width='1rem' height='1rem' />
        </div>
    );
}

Loader.propTypes = {
    white: PropTypes.bool,
};

Loader.defaultProps = {
    white: false,
};

import * as React from 'react';

export default function Close() {
    return (
        <svg fill='none' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 13 13' width='1em' height='1em'>
            <path
                d='M7.416 6.004l4.3-4.29a1.004 1.004 0 10-1.42-1.42l-4.29 4.3-4.29-4.3a1.004 1.004 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 00.325 1.639 1 1 0 001.095-.219l4.29-4.3 4.29 4.3a1.002 1.002 0 001.639-.325 1 1 0 00-.22-1.095l-4.3-4.29z'
                fill='#000'
            />
        </svg>
    );
}

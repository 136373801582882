import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

// Components
import Calendar from "../../components/Calendar/Calendar";
import NotificationList from "../../components/Notification/NotificationList";

// Contexts
import NotificationContext from "../../contexts/notification-context";

// VARIABLES
const { REACT_APP_WEBSERVICE_URL } = process.env;

export default function Agenda({ setIsAuth }) {
    const history = useHistory();
    const [notificationsNotSeen, setNotificationsNotSeen] = useState(0);
    const [notificationListIsOpen, setNotificationListIsOpen] = useState(false);
    const [notifications, setNotifications] = useState([]);

    const getNotifications = () => {
        const token = localStorage.getItem("dockersBastiaToken");
        const options = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                "Access-Control-Request-Headers": " Authorization",
                Authorization: `${token}`,
            },
            method: "POST",
            body: JSON.stringify({
                methode: "liste_notification",
            }),
        };
        fetch(REACT_APP_WEBSERVICE_URL, options)
            .then((res) => {
                if (res.status === 401) {
                    history.push("/login");
                }
                res.json();
            })
            .then((data) => {
                if (data && data.notification) {
                    setNotifications(data.notification);
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    useEffect(() => {
        getNotifications();
    }, []);

    useEffect(() => {
        if (notifications) {
            setNotificationsNotSeen(notifications.length);
        }
    }, [notifications]);

    const openNotification = () => {
        setNotificationListIsOpen(true);
        getNotifications();
    };

    return (
        <NotificationContext.Provider value={[notificationsNotSeen, setNotificationsNotSeen]}>
            <Calendar openNotification={openNotification} />
            <NotificationList
                notificationListIsOpen={notificationListIsOpen}
                setNotificationListIsOpen={setNotificationListIsOpen}
                notifications={notifications}
                setIsAuth={setIsAuth}
            />
        </NotificationContext.Provider>
    );
}

Agenda.propTypes = {
    setIsAuth: PropTypes.func.isRequired,
};

import React, { useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ isShowing, hide, children }) => {
    const modal = useRef(null);

    const closeModal = () => {
        modal.current.classList.add('hide');
        setTimeout(() => {
            hide();
        }, 300);
    };

    useEffect(() => {
        if (isShowing) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'scroll';
        }

        return () => {
            document.body.style.overflowY = 'scroll';
        };
    }, [isShowing]);

    return isShowing
        ? ReactDOM.createPortal(
              <>
                  <div ref={modal} className='modal-wrapper'>
                      <div className='modal'>{children}</div>
                      <div className='modal__overlay' onClick={closeModal} aria-hidden='true' />
                  </div>
              </>,
              document.body
          )
        : null;
};

export default Modal;

import { useEffect, useState } from 'react';

// Debounce function
function debounce(fn, ms) {
    let timer;
    return () => {
        clearTimeout(timer);
        timer = setTimeout((...args) => {
            timer = null;
            fn.apply(this, args);
        }, ms);
    };
}

export default function useWindowSize() {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);

    useEffect(() => {
        const handleResize = debounce(() => {
            setSize([window.innerHeight, window.innerWidth]);
        }, 300);

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return size;
}

import React from 'react';
import PropTypes from 'prop-types';

// Components
import Notification from './Notification';

// Icons
import Close from '../../assets/icons/Close';
import LogOutIcon from '../../assets/icons/LogOutIcon';

function NotificationList({ notificationListIsOpen, setNotificationListIsOpen, notifications, setIsAuth }) {
    const logOut = () => {
        localStorage.removeItem('dockersBastiaToken');
        setIsAuth(false);
    };

    return (
        <div className={`notifications-wrapper ${notificationListIsOpen ? 'is-open' : ''}`}>
            <div
                className='overlay'
                onClick={() => setNotificationListIsOpen(false)}
                role='button'
                onKeyPress={() => setNotificationListIsOpen(false)}
                aria-hidden
            />
            <div className='notifications-list'>
                <div className='flex notifications-list__header'>
                    <p className='text-md'>Notifications</p>
                    <button type='button' onClick={() => setNotificationListIsOpen(false)}>
                        <Close />
                    </button>
                </div>
                {notifications.length > 0 ? (
                    notifications.map((notification) => (
                        <Notification
                            key={notification.id}
                            id={notification.id}
                            title={notification.title}
                            content={notification.texte}
                            isNotSeen={notification.lu !== '1'}
                        />
                    ))
                ) : (
                    <p className='px-3'>Aucune notification</p>
                )}
                <button className='flex notifications-list__footer' type='button' onClick={logOut}>
                    <p className='text-md'>Déconnexion</p>
                    <LogOutIcon />
                </button>
            </div>
        </div>
    );
}

export default NotificationList;

NotificationList.propTypes = {
    notificationListIsOpen: PropTypes.bool.isRequired,
    setNotificationListIsOpen: PropTypes.func.isRequired,
    notifications: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.string,
            title: PropTypes.string,
            content: PropTypes.string,
            isNotSeen: PropTypes.string,
        })
    ),
    setIsAuth: PropTypes.func.isRequired,
};

NotificationList.defaultProps = {
    notifications: [],
};

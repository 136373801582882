// Convertit un objet Date en chaine de caractères (ex: Lundi 19 juin 2021)
export function dateToString(date) {
    const newDate = new Date(date);
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return newDate.toLocaleDateString('fr-FR', options);
}

// Convertit un objet Date en heures et minutes (ex: 18h30)
export function dateToHours(date) {
    const newDate = new Date(date);

    let hours = newDate.getUTCHours();
    hours = `0${hours}`.slice(-2);

    const minutes = (newDate.getUTCMinutes() < 10 ? '0' : '') + newDate.getUTCMinutes();

    return `${hours}h${minutes}`;
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";

// Logo;
import logo from "../../assets/logo.png";

// Components
import Loader from "../../components/Loader/Loader";
import Btn from "../../components/Btn/Btn";

// Icons
import EyeCloseIcon from "../../assets/icons/EyeCloseIcon";
import EyeIcon from "../../assets/icons/EyeIcon";

export default function Login({ setIsAuth }) {
    const history = useHistory();

    const [formError, setFormError] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const {
        register,
        getValues,
        formState: { errors },
        handleSubmit,
    } = useForm();

    const onSubmit = async (formData) => {
        setIsSubmitting(true);
        const { login } = formData;
        const { password } = formData;

        const options = {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
            method: "POST",
            body: JSON.stringify({
                login,
                password,
            }),
        };

        fetch(`https://www.dockersbastia.com/controller/webservice|jwt-authentication`, options)
            .then((res) => res.json())
            .then((data) => {
                localStorage.setItem("dockersBastiaToken", data);
                setIsAuth(true);
                history.push("/");
            })
            .catch((error) => {
                setFormError("Problème de connexion");
                console.log(error);
            });

        setIsSubmitting(false);
    };

    const inputPassword = useRef(null);
    const { ref } = register("password");
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
        const inputPasswordLength = getValues("password").length;
        inputPassword.current.focus();
        setTimeout(() => {
            inputPassword.current.setSelectionRange(inputPasswordLength, inputPasswordLength);
        }, 0);
    };

    return (
        <div className="page-wrapper center login">
            <div className="container">
                <div className="text-center logo">
                    <img src={logo} alt="Logo" />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="grid">
                        <div className="form-group">
                            <label htmlFor="login">Numéro de téléphone</label>
                            <input id="login" type="text" className="form-control" {...register("login", { required: true })} />
                            {errors.login?.type === "required" && <p className="text-error">Login requis</p>}
                        </div>
                        <div className="form-group mb-2">
                            <label htmlFor="password">Mot de passe</label>
                            <div className="input-icon">
                                <input
                                    id="password"
                                    type={passwordShown ? "text" : "password"}
                                    className="form-control"
                                    {...register("password", { required: true })}
                                    ref={(e) => {
                                        ref(e);
                                        inputPassword.current = e;
                                    }}
                                />
                                <i onClick={togglePasswordVisiblity} className="icon" role="button" onKeyPress={togglePasswordVisiblity} tabIndex="0">
                                    {passwordShown ? <EyeIcon /> : <EyeCloseIcon />}
                                </i>
                            </div>
                            {errors.password?.type === "required" && <p className="text-error">Mot de passe requis</p>}
                        </div>
                        <Btn disabled={isSubmitting} type="submit">
                            <p className="text-bold">Connexion</p>
                            {isSubmitting && <Loader />}
                        </Btn>
                        {formError && <p className="text-error">{formError}</p>}
                    </div>
                </form>
            </div>
        </div>
    );
}

Login.propTypes = {
    setIsAuth: PropTypes.func.isRequired,
};

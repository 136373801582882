import React, { useEffect, useState } from 'react';
import './main.scss';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Hooks
import useWindowSize from './hooks/useWindowSize';

// Pages
import Login from './pages/Login/Login';
import Agenda from './sass/pages/Agenda';

function App() {
    // Rezise windows
    const [height] = useWindowSize();
    useEffect(() => {
        const vh = height * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [height]);

    // Check si il y a une JWT disponible
    const [isAuth, setIsAuth] = useState(Boolean(localStorage.getItem('dockersBastiaToken')));

    return (
        <Router>
            {!isAuth ? (
                <Login setIsAuth={setIsAuth} />
            ) : (
                <Switch>
                    <Route path='/login' exact>
                        <Login setIsAuth={setIsAuth} />
                    </Route>
                    <Route path='/' exact>
                        <Agenda setIsAuth={setIsAuth} />
                    </Route>
                </Switch>
            )}
        </Router>
    );
}

export default App;

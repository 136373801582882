import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

// Hooks
import useModal from '../../hooks/useModal';

// Components
import Modal from '../Modal/Modal';

// Contexts
import NotificationContext from '../../contexts/notification-context';

// VARIABLES
const { REACT_APP_WEBSERVICE_URL } = process.env;

function Notification({ id, title, content, isNotSeen }) {
    const [notificationsNotSeen, setNotificationsNotSeen] = useContext(NotificationContext);
    const { modalIsShowing, toggleModal } = useModal();
    const [notificationIsNotSeen, setNotificationIsNotSeen] = useState(isNotSeen);

    const openNotification = (notificationID) => {
        if (isNotSeen) {
            setNotificationsNotSeen(notificationsNotSeen - 1);
        }

        toggleModal();
        setNotificationIsNotSeen(false);

        const token = localStorage.getItem('dockersBastiaToken');
        const options = {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                'Access-Control-Request-Headers': ' Authorization',
                Authorization: `${token}`,
            },
            method: 'POST',
            body: JSON.stringify({
                methode: 'lire_notification',
                param1: notificationID,
            }),
        };
        fetch(REACT_APP_WEBSERVICE_URL, options)
            .then((res) => res.json())
            .then((data) => {
                if (data.status !== true) {
                    alert("Un problème est survenu, la notification n'a pas pu être marquée comme lue");
                }
            })
            .catch((error) => {
                alert(error);
            });
    };

    return (
        <>
            <button className={`notification ${notificationIsNotSeen && 'is-not-seen'}`} onClick={() => openNotification(id)} type='button'>
                <p>{title}</p>
            </button>

            {modalIsShowing && (
                <Modal isShowing={modalIsShowing} hide={toggleModal}>
                    <p className='text-bold mb-2'>{title}</p>
                    <p dangerouslySetInnerHTML={{ __html: content }} />
                </Modal>
            )}
        </>
    );
}

export default Notification;

Notification.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    isNotSeen: PropTypes.bool,
};

Notification.defaultProps = {
    isNotSeen: false,
};
